import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Category, CategoryListResponse} from '../core/interfaces/category.interface';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    getCategoriesList(): Observable<Category[]> {
        return this.http.get<CategoryListResponse>(`${this.apiUrl}categories`, {}).pipe(
            map(x => x.categories)
        );
    }
}
